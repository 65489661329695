import { FC } from 'react';

import { Box } from '@mui/material';
import { UnitsType } from 'app/api/quotes';

import { ProductsActionsButtons } from '../ProductsActionsButtons';
import { styles } from './styles';

interface MobileProductsActionsButtonsProps {
  onRequestQuoteClick: (quantity?: number, units?: UnitsType) => void;
  inStock: boolean;
}

export const MobileProductsActionsButtons: FC<MobileProductsActionsButtonsProps> = ({
  onRequestQuoteClick,
  inStock,
}) => {
  return (
    <Box sx={styles.wrap}>
      <ProductsActionsButtons inStock={inStock} onRequestQuoteClick={onRequestQuoteClick} />
    </Box>
  );
};
