import { FC, MouseEvent } from 'react';
import { IconButtonProps, IconButton } from '@mui/material';
import { ReactComponent as BookmarkIcon } from 'assets/icons/bookmark.svg';

import { CommonTooltip } from 'components/CommonTooltip';

import { styles } from './styles';

type SaveButtonProps = IconButtonProps & {
  isActive: boolean;
  onClick?: (e: MouseEvent) => void;
  size?: 'small' | 'large';
};
export const SaveButton: FC<SaveButtonProps> = ({ sx, isActive, onClick, size = 'large', ...props }) => {
  return (
    <CommonTooltip title={isActive ? 'Unsave' : 'Save'} placement="top" sx={styles.tooltip}>
      <IconButton
        sx={{ ...styles.button(isActive, size === 'large', size === 'small'), ...(sx as IconButtonProps) }}
        {...props}
        onClick={onClick}
      >
        <BookmarkIcon />
      </IconButton>
    </CommonTooltip>
  );
};
