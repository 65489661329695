import { useEffect, useRef } from 'react';

import { matchPath, useLocation } from 'react-router-dom';
import * as tracker from 'analytics/tracker';
import { ROUTES } from 'app/routes/constants';

/**
 * List of routes that should NOT be auto tracked
 * these routes should be tracked explicitly on their own page
 */
export const EXPLICIT_PAGE_TRACK_ROUTES = [
  ROUTES.buyer.products,
  ROUTES.buyer.productsExtended,
  ROUTES.common.product,
  ROUTES.common.company,
];

export const useTrackPageViewedEvent = () => {
  const pathnameRef = useRef<string>();
  const { pathname, search } = useLocation();

  const fullPath = `${pathname}${search}`;

  useEffect(() => {
    if (fullPath !== pathnameRef.current) {
      const isTrackedExplicitly = EXPLICIT_PAGE_TRACK_ROUTES.some((path) => matchPath(path, pathname));
      if (!isTrackedExplicitly) {
        tracker.page();
      }

      pathnameRef.current = fullPath;
    }
  }, [fullPath, pathname, search]);
};
