import { FC, useRef, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { generatePath } from 'react-router-dom';

import { ROUTES } from 'app/routes/constants';
import { CommonTooltip, PageLoader } from 'components';
import { Footer } from 'layout/Footer';
import { ReactComponent as LockIcon } from 'assets/icons/locked.svg';
import { GeneticType, ProductCompany, ProductDetail } from 'app/api/products';
import { Link } from 'components/Link';
import secureDocument from 'assets/images/secure_document.png';
import { ReactComponent as CurrencyIcon } from 'assets/icons/currency.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { selectIsAnonymousUser, setSignupModal } from 'state/slices/account';

import {
  KeyStatsSection,
  Description,
  FullStatsSection,
  MobileProductsActionsButtons,
  LegendSection,
} from './components';
import { useProductDetailsPageHandlers } from './hooks/useProductDetailsPageHandlers';
import { styles } from './styles';
import { SimilarProducts } from './components/SimilarProducts';

const HeadData: FC<{ data: ProductDetail }> = ({ data }) => {
  let name = data.crop;
  let description = data.crop;
  if (data.variety) {
    name = `${name} ${data.variety}`;
    description = `${data.variety} ${description}`;
  }
  if (data.geneticType) {
    if (data.geneticType === GeneticType.hybrid) {
      name = `Hybrid ${name}`;
    } else if (data.geneticType === GeneticType.openPollinated) {
      name = `Open Pollinated ${name}`;
    }
  }

  description = `${description} offered from a top supplier from ${data.company.country}.`;
  if (data.description) {
    description = `${description} ${data.description}`;
  }

  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name,
    description: data.description,
    ...(data.color ? { color: data.color } : {}),
    image: data.image,
  };
  return (
    <Helmet>
      <title>{`${name} | Bulk Seed for Commercial Trade`}</title>
      <meta name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      <meta property="og:title" content={`${name} | Bulk Seed for Commercial Trade`} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://sproutzo.com/product/${data.id}`} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`https://sproutzo.com/product/${data.id}`} />
      <link rel="canonical" href={`https://sproutzo.com/product/${data.id}`} />
      {data.image && <meta property="og:image" content={data.image} />}
    </Helmet>
  );
};
const messages = {
  supplier: 'Supplier: ',
  country: 'Country: ',
  est: 'Est.: ',
  features: {
    quality: 'Quality certificates',
    payments: 'Flexible payments',
    sample: 'Sample requests',
  },
};
export const CompanySection = ({ company, width }: { company: ProductCompany; width: number }) => {
  const isAnonymousUser = useAppSelector(selectIsAnonymousUser);
  const dispatch = useAppDispatch();
  const onClickUnlock = () => dispatch(setSignupModal({ isOpen: true, state: 'welcome', source: 'company' }));
  const path = generatePath(ROUTES.common.company, { displayId: company.displayId });

  return (
    <Box sx={styles.companyContainer}>
      <Box sx={styles.companyLeft(width)}>
        <Box sx={styles.imgContainer}>
          {isAnonymousUser ? (
            <Box sx={styles.blankCompanyImageContainer}>
              <LockIcon />
            </Box>
          ) : (
            <Link path={path} sx={styles.companyLink}>
              {company.logo && (
                <Box
                  component="img"
                  loading="lazy"
                  src={company.logo}
                  alt={company.name ?? ''}
                  sx={styles.companyImage}
                />
              )}
            </Link>
          )}
        </Box>

        <Box sx={[styles.companySection, styles.companyStats]}>
          <Box sx={styles.companyStat}>
            {messages.supplier}
            <Typography component="span" sx={styles.companyValue}>
              {isAnonymousUser ? (
                <CommonTooltip sx={styles.tooltipWrap} title="Request free access to unlock" placement="bottom-start">
                  <Box component="a" sx={styles.locked} onClick={onClickUnlock}>
                    Unlock
                  </Box>
                </CommonTooltip>
              ) : (
                <Link path={path} sx={styles.companyNameLink}>
                  {company.name}
                </Link>
              )}
            </Typography>
          </Box>
          <Box sx={styles.companyStat}>
            {messages.country}
            <Typography component="span" sx={styles.companyValue}>
              {company.country}
            </Typography>
          </Box>
          {company.yearEstablished && !isAnonymousUser && (
            <Box sx={styles.companyStat}>
              {messages.est}
              <Typography component="span" sx={styles.companyValue}>
                {company.yearEstablished}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Box sx={styles.companySection}>
        <Box sx={styles.companyFeatures}>
          <Box component="img" src={secureDocument} sx={styles.companyFeatureIcon} />
          {messages.features.quality}
        </Box>
        <Box sx={styles.companyFeatures}>
          <CurrencyIcon />
          {messages.features.payments}
        </Box>
        <Box sx={styles.companyFeatures}>
          <SearchIcon />
          {messages.features.sample}
        </Box>
      </Box>
    </Box>
  );
};

export const ProductDetailsPage: FC = () => {
  const {
    isBuyer,
    isLoading,
    data,
    keyTechStats,
    fullTechStats,
    hideRequestButton,
    isVerticalImage,
    legendImage,
    handleImageLoad,
    onRequestQuoteClick,
    crop,
  } = useProductDetailsPageHandlers();

  const similarProductsRef = useRef<HTMLDivElement>(null);
  const scrollDown = (e?: MouseEvent) => {
    e?.preventDefault();
    if (similarProductsRef.current) {
      similarProductsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [width, setWidth] = useState(0);

  if (isLoading) {
    return (
      <Box sx={{ ...styles.loaderWrap }}>
        <PageLoader />
      </Box>
    );
  }
  return (
    <>
      <Box sx={styles.pageWrap(isBuyer)}>
        {data ? (
          <>
            <HeadData data={data} />

            <KeyStatsSection
              setWidth={setWidth}
              crop={data.crop}
              cropFamily={crop?.family?.name}
              scrollDown={scrollDown}
              variety={data.variety}
              name={data.name}
              image={data.image}
              inStock={data.inStock}
              isSaved={data.isSaved}
              productId={data.id}
              stats={keyTechStats}
              minOrder={data.company.smallestShipment}
              minOrderType={data.company.smallestShipmentType}
              hideButton={hideRequestButton}
              isLegend={!!legendImage}
              isVerticalImage={isVerticalImage}
              onRequestQuoteClick={onRequestQuoteClick}
              handleImageLoad={handleImageLoad}
            />

            <Box sx={styles.legendWrap}>{!!legendImage && <LegendSection src={legendImage} />}</Box>
            <CompanySection company={data.company} width={width} />
            <FullStatsSection stats={fullTechStats} />
            {(!!data.description || !!legendImage) && (
              <Description content={data.description} legendImage={legendImage} />
            )}

            <SimilarProducts cropId={data.id} containerRef={similarProductsRef} />

            <Box sx={styles.footerWrap(!hideRequestButton)}>
              <Footer />
            </Box>

            {!hideRequestButton && (
              <MobileProductsActionsButtons inStock={data.inStock} onRequestQuoteClick={onRequestQuoteClick} />
            )}
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};
