import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  button: (isActive: boolean, withBorder: boolean, isSmall: boolean) => ({
    backgroundColor: customTheme.custom.white,
    ...(withBorder && {
      border: `1px solid ${customTheme.custom.black.B50}`,
    }),
    '& svg': {
      height: '24px',
      width: '24px',
      ...(isSmall && {
        height: '16px',
        width: '16px',
      }),
      stroke: customTheme.custom.black.B1000,
      fill: 'none',
      ...(isActive && {
        fill: customTheme.palette.secondary.S80,
        animation: `${customTheme.animations.grow} 0.1s ease-in-out, ${customTheme.animations.fadeIn} 0.1s ease-in-out`,
        '& path': {
          stroke: customTheme.palette.secondary.S80,
        },
      }),
    },
    ...(isActive && {
      // backgroundColor: customTheme.palette.primary.P20,
      backgroundColor: '#DBF5FA',
    }),
    '&:hover': {
      ...(isSmall && {
        backgroundColor: customTheme.custom.white,
      }),
    },
  }),
  tooltip: {
    padding: '8px',
    fontSize: '12px',
    backgroundColor: 'rgba(39, 139, 159, 0.9)',
  },
};
