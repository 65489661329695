import { FC, MouseEvent, useState } from 'react';

import { NumericFormat } from 'react-number-format';
import { useForm } from 'react-hook-form';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import { notificationObserver } from 'utils/observer';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { selectIsAnonymousUser, setSignupModal } from 'state/slices/account';

import { getSeparator } from 'utils/formatText';
import { UnitsMenu } from 'components/UnitsMenu';
import { UnitsType } from 'app/api/quotes';
import { ProductDetail } from 'app/api/products/types';
import { CommonButton, CommonInput, CommonModal, CommonTooltip } from 'components';
import moneyPlant from 'assets/images/money_plant.png';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/uploadBlack.svg';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { useDeviceType, useModal } from 'hooks';
import { postPublicForm } from 'app/api/publicForm';
import { SaveButton } from 'components/SaveButton';
import useSaveProduct from 'hooks/useSaveProduct';

import { KeyStatItem } from '../KeyStatItem';
import { styles } from './styles';

interface KeyStatsCardProps extends Pick<ProductDetail, 'crop'> {
  cropFamily?: string;
  hideButton: boolean;
  inStock: boolean;
  scrollDown: () => void;
  stats: { name: string; value: string }[];
  onRequestQuoteClick: (quantity: number, units: UnitsType) => void;
  productId: number;
  isSaved: boolean;
}

export const ProductRequestQuote: FC<{
  onRequestQuoteClick: (quantity: number, units: UnitsType) => void;
}> = ({ onRequestQuoteClick }) => {
  const { watch, setValue } = useForm<{
    quantity: number;
    units: UnitsType;
  }>({
    defaultValues: {
      quantity: undefined,
      units: UnitsType.KG,
    },
  });
  const quantity = watch('quantity');
  const units = watch('units');
  const onClick = () => onRequestQuoteClick(quantity, units);
  return (
    <Box sx={styles.requestQuoteContainer}>
      <Box sx={styles.quantity}>
        <NumericFormat
          value={quantity}
          sx={{ ...styles.quantityAvailable, input: styles.quantityAvailableInput }}
          customInput={CommonInput}
          placeholder="Order Quantity"
          decimalSeparator={getSeparator('decimal')}
          thousandSeparator={getSeparator('group')}
          decimalScale={2}
          onValueChange={(value) => {
            if (value.floatValue !== undefined) {
              setValue('quantity', value.floatValue);
            }
          }}
        />
        <UnitsMenu sxContainer={styles.unitsMenu} units={units} onChange={(value) => setValue('units', value)} />
      </Box>
      <CommonButton color="secondary" onClick={onClick} fullWidth sx={styles.requestQuoteButton}>
        Request Quote
      </CommonButton>
    </Box>
  );
};

const financingMessages = {
  body:
    'Sproutzo financing enables companies to receive shipments without upfront payments, ' +
    'easing cash flow and reducing risk. ' +
    'Apply for credit and pay only after receiving your shipments.',
  help: 'Have questions? Reach out to hello@sproutzo.com',
};

export const FinancingModal: FC<{ isOpen: boolean; handleClose: (e?: MouseEvent) => void }> = ({
  isOpen,
  handleClose,
}) => {
  const { mutate: handlePostForm } = useMutation(async () => {
    try {
      await postPublicForm({ title: 'Request Financing' });
      notificationObserver.publish({
        type: 'success',
        title: `Thank you for applying for Sproutzo financing. A member of our team will reach out soon.`,
      });
    } catch (e) {
      notificationObserver.publish({
        type: 'error',
        title: `Error requesting finacing`,
      });
    }
  }, {});

  const onClickRequest = () => {
    handlePostForm();
    handleClose();
  };

  if (!isOpen) return null;
  return (
    <CommonModal
      paperStyles={styles.modalPaper as SxPropsTypes}
      titleContainerStyles={styles.closeBtn as SxPropsTypes}
      isOpen={isOpen}
      handleClose={handleClose}
      withCloseButton
      title="Sproutzo Financing"
    >
      <Typography sx={styles.financingModalBody}>{financingMessages.body}</Typography>
      <Box sx={styles.financingModalBottom}>
        <Typography sx={styles.financingModalHelp}>{financingMessages.help}</Typography>
        <CommonButton color="secondary" onClick={onClickRequest} sx={styles.financingModalApply}>
          Apply
        </CommonButton>
      </Box>
    </CommonModal>
  );
};

export const OutOfStock: FC<{ scrollDown: () => void }> = ({ scrollDown }) => {
  return (
    <Box sx={styles.outOfStockContainer}>
      <Box sx={styles.outOfStock}>Out of stock</Box>
      <Typography sx={styles.similarProducts} onClick={scrollDown}>
        See Similar Products
      </Typography>
    </Box>
  );
};

const messages = {
  requestQuote: 'Request a quote from the supplier',
  requestDescription: 'Pay after arrival with ',
  financing: 'Sproutzo financing',
  financingTooltip: 'Learn more about Sproutzo financing',
};
export const KeyStatsCard: FC<KeyStatsCardProps> = ({
  inStock,
  hideButton,
  scrollDown,
  stats,
  crop,
  onRequestQuoteClick,
  productId,
  isSaved: isSavedProp,
}) => {
  const { isMobileSize } = useDeviceType();

  const { open: isModalOpen, handleCloseModal, handleOpenModal } = useModal();
  const isAnonymousUser = useAppSelector(selectIsAnonymousUser);
  const dispatch = useAppDispatch();
  const openSignupModal = () => dispatch(setSignupModal({ isOpen: true, state: 'requestAccess', source: 'financing' }));
  const [isSaved, setIsSaved] = useState(isSavedProp);

  const onClickFinancingInfoIcon = isAnonymousUser ? openSignupModal : handleOpenModal;
  const { save, unsave } = useSaveProduct();
  const onClickSave = () => {
    if (isAnonymousUser) {
      dispatch(setSignupModal({ isOpen: true, state: 'requestAccess', source: 'save' }));
      return;
    }
    if (isSaved) {
      unsave.mutate({ productId });
    } else {
      save.mutate({ productId });
    }
    setIsSaved(!isSaved);
  };
  const share = () => {
    const shareData = async () => {
      try {
        if (!navigator.share) {
          await navigator.clipboard.writeText(window.location.href);
          notificationObserver.publish({
            type: 'success',
            title: 'Link copied to clipboard',
          });
        } else {
          await navigator.share({
            url: window.location.href,
            title: `${crop} | Sproutzo`,
            text: `Checkout out this ${crop} on sproutzo.`,
          });
        }
      } catch (error) {
        notificationObserver.publish({
          type: 'error',
          title: 'Failed to share',
        });
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    shareData();
  };

  return (
    <Box sx={{ ...styles.wrap }}>
      <Box sx={styles.top}>
        <Box sx={styles.topContent}>
          <Typography component="h1" sx={styles.crop}>
            {crop}
          </Typography>
          <Box sx={styles.productActionContainer}>
            <CommonTooltip title="Share" placement="top" sx={styles.tooltip}>
              <IconButton sx={styles.iconButton(false)} onClick={share}>
                <UploadIcon />
              </IconButton>
            </CommonTooltip>
            <SaveButton isActive={isSaved} onClick={onClickSave} />
          </Box>
        </Box>
        <Box sx={styles.keyStatsWrap(!hideButton)}>
          <Box sx={{ ...styles.list(!hideButton) }}>
            {stats.map((stat) => (
              <KeyStatItem key={stat.name} {...stat} />
            ))}
          </Box>
        </Box>
      </Box>
      {!isMobileSize && (
        <>
          <Divider sx={{ ...styles.divider }} />
          <Box sx={styles.financingSection}>
            <Typography variant="body2" sx={styles.requestQuote}>
              {messages.requestQuote}
            </Typography>
            <Box sx={styles.requestQuotePay}>
              <Box component="img" src={moneyPlant} sx={styles.moneyPlant} />
              <Typography variant="body2" sx={styles.requestQuoteDescripton}>
                {messages.requestDescription}
                <CommonTooltip title={messages.financingTooltip} placement="top" sx={styles.financingTooltip}>
                  <Typography
                    variant="body2"
                    component="span"
                    sx={styles.requestQuoteFinance}
                    onClick={onClickFinancingInfoIcon}
                  >
                    {messages.financing}
                    <InfoIcon />
                  </Typography>
                </CommonTooltip>
              </Typography>
            </Box>
          </Box>
          <FinancingModal isOpen={isModalOpen} handleClose={handleCloseModal} />
        </>
      )}

      {!hideButton && (inStock || isAnonymousUser) && !isMobileSize && (
        <ProductRequestQuote onRequestQuoteClick={onRequestQuoteClick} />
      )}
      {!inStock && !isAnonymousUser && <OutOfStock scrollDown={scrollDown} />}
    </Box>
  );
};
