import { AxiosResponse } from 'axios';
import { apiRoutes } from '../apiRoutes';
import { Company } from '../company';
import { customerApi, fileUploadApi, productDetailsApi } from '../index';
import {
  HomePageData,
  Product,
  ProductDetail,
  ProductsFiltersRequest,
  ProductsListData,
  ProductsListRequest,
  ProductNotFoundRequest,
  ProductsSeedsMaturity,
  ProductsSeedsWeight,
  ProductsOrganicFlag,
  UpdateProduct,
} from './types';

export const getHomePageData = async () => {
  const response = await customerApi.get<HomePageData>(apiRoutes.homePage);

  return response?.data;
};

export const getFeaturedSellers = async () => {
  const response = await customerApi.get<Company[]>(apiRoutes.featuredSellers);

  return response?.data;
};

export const getProductData = async (id: number) => {
  const response = await productDetailsApi.get<ProductDetail>(apiRoutes.products.byId(id));

  return response?.data;
};

export const getProductsListQuoteRequestData = async (params: { company: string }) => {
  const response = await customerApi.get<Product[]>(apiRoutes.products._, {
    params,
  });

  return response?.data;
};

export const getProductsListData = async (params: ProductsListRequest) => {
  const response = await customerApi.get<ProductsListData>(apiRoutes.products._, {
    params,
    paramsSerializer: {
      indexes: null,
    },
  });

  return response?.data;
};

export const getSimilarProducts = async (id: number) => {
  const response = await customerApi.get<ProductsListData>(apiRoutes.products.similar(id));
  return response?.data;
};

export const patchProduct = async (id: number, updateFields: UpdateProduct) => {
  // eslint-disable-next-line
  return customerApi.patch<any, AxiosResponse<Product>>(apiRoutes.products.byId(id), updateFields);
};

export const postProductImage = async (id: number, image: File) => {
  return fileUploadApi.post(apiRoutes.products.imageById(id), { image });
};

export const getProductsColors = async (params: ProductsFiltersRequest) => {
  const response = await customerApi.get<string[]>(apiRoutes.products.colors, { params });

  return response?.data;
};

export const getProductsContinents = async (params: ProductsFiltersRequest) => {
  const response = await customerApi.get<string[]>(apiRoutes.products.continents, { params });

  return response?.data;
};

export const createproductNotFoundRequests = async (data: ProductNotFoundRequest) => {
  await customerApi.post(apiRoutes.productNotFoundRequests._, data);
};

export const getProductsSeedsWeight = async (params: ProductsFiltersRequest) => {
  const response = await customerApi.get<ProductsSeedsWeight>(apiRoutes.products.seedsWeight, { params });

  return response?.data;
};

export const getProductsSeedsMaturity = async (params: ProductsFiltersRequest) => {
  const response = await customerApi.get<ProductsSeedsMaturity>(apiRoutes.products.seedsMaturity, { params });

  return response?.data;
};

// SPZ-1550: update
export const getProductsSeedsResistances = async (params: ProductsFiltersRequest) => {
  const response = await customerApi.get<string[]>(apiRoutes.products.seedsResistances, { params });

  return response?.data;
};

export const getProductsOrganicFilter = async (params: ProductsFiltersRequest) => {
  const response = await customerApi.get<ProductsOrganicFlag>(apiRoutes.products.organicSeed, { params });

  return response?.data;
};

export const getSavedProducts = async (page: number) => {
  const limit = 100;
  const offset = page * limit;
  const response = await customerApi.get<ProductsListData>(apiRoutes.products.savedProducts, {
    params: { limit, offset },
  });

  return response?.data;
};

export const getSavedProduct = async (productId: number) => {
  const response = await customerApi.get<ProductsListData>(apiRoutes.products.saveById(productId));
  return response?.data;
};

export const saveProduct = async (productId: number) => {
  const response = await customerApi.post<ProductsListData>(apiRoutes.products.saveById(productId));
  return response?.data;
};

export const unsaveProduct = async (productId: number) => {
  const response = await customerApi.post<ProductsListData>(apiRoutes.products.unsaveById(productId));
  return response?.data;
};
