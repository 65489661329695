import { FC } from 'react';

import { Box } from '@mui/material';

import { useAuth } from 'hooks';
import { useForm } from 'react-hook-form';
import { CommonButton, CommonInput, CommonModal, TextFormField } from 'components';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { useUpdateCompanyDetails } from 'pages/CompanyDetailsPage/hooks/updateCompanyDetails';
import { UnitsType } from 'app/api/quotes';
import { UnitsMenu } from 'components/UnitsMenu';

import { getSeparator } from 'utils/formatText';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
// import { useAuth } from 'hooks';
import { styles } from './styles';

interface EditCompanyDescriptionProps {
  handleClose: () => void;
  isOpen: boolean;
  displayId: string;
}

const messages = {
  description: 'Company Description',
  minOrder: 'Minimum Order',
};

type CompanyFormData = {
  description: string;
  smallestShipment: number;
  smallestShipmentType: UnitsType;
};

export const EditCompanyDescription: FC<EditCompanyDescriptionProps> = ({ displayId, handleClose, isOpen = false }) => {
  const { activeUser, isBuyer } = useAuth();
  const { handleUpdateCompany } = useUpdateCompanyDetails(displayId, handleClose);

  const onClickSave = (data: CompanyFormData) => {
    handleUpdateCompany(data);
  };
  const { control, handleSubmit, watch, setValue } = useForm<CompanyFormData>({
    defaultValues: {
      description: activeUser?.company.description || '',
      smallestShipmentType: UnitsType.KG,
    },
  });
  const quantity = watch('smallestShipment');
  const units = watch('smallestShipmentType');
  const MAX_SMALLEST_SHIPMENT = 10000;
  const withValueLimit = (values: NumberFormatValues) => (values?.floatValue ?? 0) <= MAX_SMALLEST_SHIPMENT;

  return (
    <CommonModal title="Company" isOpen={isOpen} handleClose={handleClose}>
      <Box sx={styles.modalPaper as SxPropsTypes}>
        <Box component="label" sx={styles.inputLabel}>
          {messages.description}
        </Box>
        <TextFormField
          control={control}
          name="description"
          placeholder="Company Description"
          multiline
          rows={8}
          inputWrapStyles={styles.descriptionInput as SxPropsTypes}
        />
      </Box>
      {!isBuyer && (
        <Box>
          <Box component="label" sx={styles.inputLabel}>
            {messages.minOrder}
          </Box>
          <Box sx={styles.minOrderContainer}>
            <NumericFormat
              value={quantity}
              isAllowed={withValueLimit}
              sx={{
                ...styles.numberInputWrapper,
              }}
              customInput={CommonInput}
              thousandSeparator={getSeparator('group')}
              decimalScale={0}
              placeholder="Minimum Order"
              onValueChange={(value) => {
                if (value.floatValue !== undefined) {
                  setValue('smallestShipment', value.floatValue);
                }
              }}
            />
            <UnitsMenu
              sxContainer={styles.unitsMenu}
              units={units}
              onChange={(value) => setValue('smallestShipmentType', value)}
            />
          </Box>
        </Box>
      )}

      <CommonButton fullWidth color="primary" sx={styles.saveButton} onClick={handleSubmit(onClickSave)}>
        Save
      </CommonButton>
    </CommonModal>
  );
};
