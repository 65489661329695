import { getAccessInfo, getActiveUserId } from 'utils/storage';
import { Questionaire } from 'pages/BuyerOnboardingQuestionairePage/hooks';

import { contentApi, customerApi } from '../index';
import { apiRoutes } from '../apiRoutes';
import { CustomerProfile, PatchUser, UserData, OnboardingData } from './types';

export const getUserData = async () => {
  const response = await contentApi.get<UserData[]>(apiRoutes.user.customers, {
    headers: { Authorization: `token ${getAccessInfo('token') ?? ''}` },
  });
  return response?.data;
};

export const getCustomerProfile = async (id: number) => {
  const response = await customerApi.get<CustomerProfile>(apiRoutes.user.customerProfile(id));

  return response?.data;
};

export const getUserOnboarding = async (userId: number) => {
  const response = await customerApi.get<OnboardingData>(apiRoutes.user.onboardingUser(userId));

  return response?.data;
};

export const postOnboardingQuestionaire = async (data: Questionaire) => {
  const userId = getActiveUserId();
  if (!userId) return null;

  const response = await customerApi.post<Questionaire>(apiRoutes.user.onboarding, {
    data,
  });

  return response?.data;
};

export const postOnboarding = async (data: OnboardingData) => {
  const response = await customerApi.post<OnboardingData>(apiRoutes.user.onboarding, {
    ...data,
  });

  return response?.data;
};

export const patchOnboarding = async (data: OnboardingData) => {
  const { user: userId } = data;
  const response = await customerApi.patch<OnboardingData>(apiRoutes.user.onboardingUser(userId), data);

  return response?.data;
};

export const patchUser = async (userId: number, updateFields: PatchUser) => {
  return customerApi.patch(apiRoutes.user.user(userId), updateFields);
};
