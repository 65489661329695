import { FC } from 'react';
import { Box, SxProps } from '@mui/material';

import { CommonButton } from 'components/CommonButton';
import { Link } from 'components/Link';
import { ReactComponent as RightRightIcon } from 'assets/icons/rightright.svg';
import { ROUTES } from 'app/routes/constants';

import { styles } from './styles';

const messages = {
  description:
    'Here’s how your profile will display to buyers browsing the platform. ' +
    'Click on any element to edit it, or click the button to the right to confirm it looks good. ' +
    'You can come back to edit this page at any time.',
};
type OnboardingBannerProps = { containerStyles?: SxProps };
const OnboardingBanner: FC<OnboardingBannerProps> = ({ containerStyles = {} }) => {
  const onboardingPage = ROUTES.common.onboarding._;

  return (
    <Box sx={{ ...styles.container, ...containerStyles }}>
      <Box sx={{ ...styles.content }}>
        <Box sx={styles.title}>{messages.description}</Box>
        <Link path={onboardingPage}>
          <CommonButton variant="contained" color="secondary" sx={styles.btn}>
            Looks Good
            <RightRightIcon />
          </CommonButton>
        </Link>
      </Box>
    </Box>
  );
};

export default OnboardingBanner;
