import { useMutation } from 'react-query';
import { saveProduct, unsaveProduct } from 'app/api/products';

export const useSaveProduct = () => {
  const save = useMutation({
    mutationFn: async (data: { productId: number }) => {
      return saveProduct(data.productId);
    },
  });

  const unsave = useMutation({
    mutationFn: async (data: { productId: number }) => {
      return unsaveProduct(data.productId);
    },
  });

  return { save, unsave };
};

export default useSaveProduct;
