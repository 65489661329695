import { createListenerMiddleware, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as tracker from 'analytics/tracker';
import { AnalyticsName } from 'analytics';

import { UserData } from 'app/api/user/types';
import { RootState } from 'state/store';

// Define a type for the slice state
export interface AccountState {
  isSignedIn: boolean;
  user: UserData | null;
  signupModal: {
    isOpen: boolean;
    state?: 'welcome' | 'login' | 'requestAccess';
  };
  onboardingModal: {
    isOpen: boolean;
  };
}

// Define the initial state using that type
const initialState: AccountState = {
  isSignedIn: false,
  user: null,
  signupModal: {
    isOpen: false,
    state: 'welcome',
  },
  onboardingModal: {
    isOpen: false,
  },
};

export const acountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setUserSignedIn: (state, action: PayloadAction<{ isSignedIn: boolean; user: UserData | null }>) => {
      state.isSignedIn = action.payload.isSignedIn;
      state.user = action.payload.user;
    },
    setSignupModal: (
      state,
      action: PayloadAction<{
        isOpen?: boolean;
        state?: 'welcome' | 'login' | 'requestAccess';
        source?:
          | 'header'
          | 'product'
          | 'about'
          | 'search'
          | 'contact'
          | 'company'
          | 'unlock product'
          | 'unlock supplier'
          | 'quote'
          | 'save'
          | 'financing';
      }>,
    ) => {
      if (action.payload.isOpen !== undefined) {
        state.signupModal.isOpen = action.payload.isOpen;
      }
      if (action.payload.state !== undefined) {
        state.signupModal.state = action.payload.state;
      }
    },
    setOnboardingModal: (
      state,
      action: PayloadAction<{
        isOpen?: boolean;
      }>,
    ) => {
      if (action.payload.isOpen !== undefined) {
        state.onboardingModal.isOpen = action.payload.isOpen;
      }
    },
  },
});

export const { setUserSignedIn, setSignupModal, setOnboardingModal } = acountSlice.actions;

// Create the middleware instance and methods
export const accountListenerMiddleware = createListenerMiddleware();

accountListenerMiddleware.startListening({
  actionCreator: setSignupModal,
  effect: (action) => {
    if (action.payload.isOpen && action.payload.state !== 'login') {
      let name = AnalyticsName.REQUEST_ACCESS_OPEN;
      if (action.payload.source) {
        name = `${name}_${action.payload.source.toUpperCase()}` as AnalyticsName;
      }
      tracker.track(name, {
        source: action.payload.source,
      });
    }
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectIsAnonymousUser = (state: RootState) => {
  return !state.account.isSignedIn;
};

export const selectSignupModal = (state: RootState) => {
  return state.account.signupModal;
};

export const selectOnboardingModal = (state: RootState) => {
  return state.account.onboardingModal;
};

export default acountSlice.reducer;
