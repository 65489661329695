import { FC, useCallback, useState } from 'react';

import { ClickAwayListener } from '@mui/base';
import { Box, Tooltip } from '@mui/material';
import { UnitsType } from 'app/api/quotes';

import { CommonButton } from 'components';
import { styles } from './styles';

interface ProductsActionsButtonsProps {
  inStock?: boolean;
  onRequestQuoteClick: (quantity?: number, units?: UnitsType) => void;
}

const messages = {
  outOfStock: 'Out of stock: ',
  outOfStockDetails:
    'This product is out of stock. You can still message the supplier ' +
    'to ask about expected availability, contract productions, or similar products',
  requestQuote: 'Request Quote',
  requestSample: 'Request Sample',
  askQuestion: 'Ask a Question',
};

export const OutOfStockTooltip: FC = () => {
  return (
    <Box sx={styles.tooltip}>
      <Box component="b">{messages.outOfStock}</Box>
      {messages.outOfStockDetails}
    </Box>
  );
};

const useOpenClose = (shouldOpen: boolean) => {
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpen = useCallback(() => {
    if (shouldOpen) {
      setOpen(true);
    }
  }, [shouldOpen, setOpen]);
  return { open, handleClose, handleOpen };
};
export const ProductsActionsButtons: FC<ProductsActionsButtonsProps> = ({ onRequestQuoteClick, inStock = true }) => {
  const tooltipTitle = inStock ? '' : <OutOfStockTooltip />;
  const { open: quoteOpen, handleOpen: handleQuoteOpen, handleClose: handleQuoteClose } = useOpenClose(!inStock);

  return (
    <Box sx={styles.actionsWrap}>
      <ClickAwayListener onClickAway={handleQuoteClose}>
        <Box onClick={handleQuoteOpen} sx={styles.btnContainer}>
          <Tooltip
            title={tooltipTitle}
            placement="top"
            open={quoteOpen}
            onClose={handleQuoteClose}
            onOpen={handleQuoteOpen}
          >
            <Box component="div">
              <CommonButton sx={styles.quoteButton} onClick={() => onRequestQuoteClick()} disabled={!inStock}>
                {messages.requestQuote}
              </CommonButton>
            </Box>
          </Tooltip>
        </Box>
      </ClickAwayListener>
    </Box>
  );
};
