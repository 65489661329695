import { FC, useState } from 'react';

import { Avatar, Box } from '@mui/material';

import { useForm } from 'react-hook-form';
import { useAuth } from 'hooks';
import { CommonButton, CommonModal, TextFormField } from 'components';
import { getInitials } from 'utils/userName';
import { useDropzone } from 'react-dropzone';
import { patchCompanyTeamMemberData, postCompanyTeamMemberImage } from 'app/api/company';
import { PatchTeamMemberData } from 'app/api/company/types';
import { useMutation, useQueryClient } from 'react-query';
import { notificationObserver } from 'utils/observer';

import { styles } from './styles';

interface EditUserModalProps {
  handleClose: () => void;
  isOpen: boolean;
  id: number;
  img: string;
  name: string;
  role: string;
}

const messages = {
  name: 'Name',
  editPhoto: 'Change Photo',
  role: 'Company Position',
};

type TeamMemberFormData = {
  name: string;
  role: string;
};

export const useUpdateTeamMemberDetails = (id: number, companyDisplayId: string, onUpdate?: () => void) => {
  const queryClient = useQueryClient();

  const { mutate: handleUpdateTeamMember } = useMutation(async (data: PatchTeamMemberData) => {
    try {
      await patchCompanyTeamMemberData(id, data);
      notificationObserver.publish({
        type: 'success',
        title: 'Updated team member details',
      });
      await queryClient.invalidateQueries({ queryKey: ['company', companyDisplayId] });
      if (onUpdate) onUpdate();
    } catch (err) {
      notificationObserver.publish({
        type: 'warning',
        title: 'Updated update team member details',
      });
    }
  }, {});

  return { handleUpdateTeamMember };
};

export const EditUserModal: FC<EditUserModalProps> = ({ id, img, name, role, handleClose, isOpen = false }) => {
  const [image, setImage] = useState<{ file: File; url: string } | undefined>();

  const { getRootProps: getDropzoneRootProps } = useDropzone({
    onDrop: (files: File[]) =>
      setImage({
        file: files[0],
        url: URL.createObjectURL(files[0]),
      }),
    maxSize: 15000000,
    multiple: false,
    noClick: false,
  });
  const { activeUser } = useAuth();
  const companyDisplayId = activeUser?.company?.displayId || '';
  const { handleUpdateTeamMember } = useUpdateTeamMemberDetails(id, companyDisplayId, handleClose);
  const queryClient = useQueryClient();
  const updateTeamMemberImage = useMutation({
    mutationFn: async (data: { id: number; image: File }) => {
      await postCompanyTeamMemberImage(data.id, data.image);
      await queryClient.invalidateQueries({ queryKey: ['company', companyDisplayId] });
    },
  });

  const onClickSave = (data: TeamMemberFormData) => {
    if (image) {
      updateTeamMemberImage.mutate({ id, image: image.file });
    }
    handleUpdateTeamMember(data);
  };

  const { control, handleSubmit } = useForm<TeamMemberFormData>({
    defaultValues: {
      name,
      role,
    },
  });

  return (
    <CommonModal title="Employee Snapshot" isOpen={isOpen} handleClose={handleClose}>
      <Box sx={styles.top}>
        <Avatar alt={name} src={image?.url || img} sx={styles.avatar}>
          {getInitials(name)}
        </Avatar>
        <Box {...getDropzoneRootProps()} sx={styles.dropzone}>
          <CommonButton color="primary" variant="outlined" sx={styles.editButton}>
            {messages.editPhoto}
          </CommonButton>
        </Box>
      </Box>
      <Box sx={styles.bottom}>
        <Box>
          <Box component="label" sx={styles.inputLabel}>
            {messages.name}
          </Box>
          <TextFormField control={control} name="name" placeholder="Name" />
        </Box>
        <Box>
          <Box component="label" sx={styles.inputLabel}>
            {messages.role}
          </Box>
          <TextFormField control={control} name="role" placeholder="Position" />
        </Box>
      </Box>

      <CommonButton fullWidth color="primary" sx={styles.saveButton} onClick={handleSubmit(onClickSave)}>
        Save
      </CommonButton>
    </CommonModal>
  );
};
