import { customTheme } from 'theme/MuiThemeProvider/theme';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  button: {
    width: '24px',
    height: '24px',
    padding: 0,
    backgroundColor: customTheme.custom.black.B10,
    transition: 'background-color 0.3s',
  },
};
