import { useCallback, useEffect, useMemo, useState } from 'react';

import { UseFormWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import * as tracker from 'analytics/tracker';

import { DashboardMainTabs, QuoteRequestData, SampleRequestsTabs, UnitsType } from 'app/api/quotes/types';
import { addQuoteProduct, sendQuoteRequest } from 'app/api/quotes/service';
import { CompanyDetail } from 'app/api/company/types';
import { ROUTES } from 'app/routes/constants';
import { AnalyticsName, getQuoteRequestedEventProducts } from 'analytics';
import { asyncGenerator } from 'utils/asyncGenerator';
import { notificationObserver } from 'utils/observer';
import { QuoteFormData } from '../../../hooks/types';

interface UseChoosedProductsProps {
  watch: UseFormWatch<QuoteFormData>;
  seller: CompanyDetail | undefined;
  isSampleType: boolean;
}

export const useChoosedProducts = ({ watch, seller, isSampleType }: UseChoosedProductsProps) => {
  const navigate = useNavigate();
  const { quoteId } = useParams();
  const quotesList = watch('quotes');

  const [formError, setFormError] = useState('');

  const {
    mutate: quoteRequest,
    isLoading,
    isSuccess,
  } = useMutation((data: QuoteRequestData) => sendQuoteRequest(data), {
    onSuccess: (data) => {
      const analyticsOptions = {
        quote_id: data.id,
        products: getQuoteRequestedEventProducts(quotesList),
        number_of_products: quotesList.length,
        supplier_id: seller?.id ?? '',
        is_sample_request: isSampleType,
      };

      tracker.track(AnalyticsName.QUOTE_REQUESTED, analyticsOptions);

      // eslint-disable-next-line no-restricted-syntax
      for (const product of quotesList) {
        const analyticsOptions = {
          quote_id: data.id,
          product_id: product.id,
          crop_id: product.crop,
          amount: product.value,
          amount_type: product.unitsType,
          supplier_id: seller?.id ?? '',
          trigger: 'on_create',
        };
        tracker.track(AnalyticsName.QUOTE_PRODUCT_REQUEST, analyticsOptions);
      }
    },
  });

  const {
    mutate: addQuote,
    isLoading: addQuoteLoading,
    isSuccess: isSuccessAddQuote,
  } = useMutation(
    (
      products: {
        product: number;
        cropId: string;
        amount: number;
        amountType: UnitsType;
      }[],
    ) => {
      const addAllProducts = async () => {
        // eslint-disable-next-line no-restricted-syntax
        for await (const num of asyncGenerator(products.length)) {
          const product = products[num];

          await addQuoteProduct({
            quote: Number(quoteId),
            amount: product.amount,
            product: product.product,
            amountType: product.amountType,
          });

          // eslint-disable-next-line no-restricted-syntax
          const analyticsOptions = {
            quote_id: quoteId,
            product_id: product.product,
            crop_id: product.cropId,
            amount: product.amount,
            amount_type: product.amountType,
            trigger: 'on_add',
          };
          tracker.track(AnalyticsName.QUOTE_PRODUCT_REQUEST, analyticsOptions);
        }
      };

      return addAllProducts();
    },
  );

  const onSubmit = (values: QuoteFormData) => {
    const { priceUnits, quotes } = values;
    const isSomeEmpty = quotes.some(({ value }) => !value);

    if (isSomeEmpty) {
      setFormError(`Please check Amount for selected products. Highlighted field is required. `);
      return;
    }

    const products = quotes.map((quote) => ({
      product: quote.id,
      cropId: quote.crop,
      amount: Number(quote.value),
      amountType: quote.unitsType,
    }));

    if (quoteId) {
      addQuote(products);
    } else {
      const unitsType: UnitsType = products.length > 0 ? products[0].amountType : UnitsType.KG;

      quoteRequest({
        sellerCompany: quotes?.[0].company.id,
        priceUnits,
        products,
        unitsType,
        isSampleType,
      });
    }
  };

  const onChange = useCallback(() => {
    if (formError) {
      setFormError('');
    }
  }, [formError]);

  useEffect(() => {
    const subscription = watch(() => {
      onChange();
    });
    return () => subscription?.unsubscribe();
  }, [watch, onChange]);

  useEffect(() => {
    if (isSuccess) {
      notificationObserver.publish({
        type: 'success',
        title: `${isSampleType ? 'Sample' : 'Quote'} Request sent successfully `,
      });

      const path = isSampleType
        ? generatePath(ROUTES.buyer.dashboard.tab, { tab: SampleRequestsTabs.SAMPLE_REQUESTS })
        : generatePath(ROUTES.buyer.dashboard.tab, { role: 'buyer', tab: DashboardMainTabs.QUOTE_REQUESTS });

      navigate(path);
    }
  }, [isSampleType, isSuccess, navigate]);

  useEffect(() => {
    if (isSuccessAddQuote) {
      const pathname = isSampleType
        ? generatePath(ROUTES.buyer.dashboard.tab, { tab: SampleRequestsTabs.SAMPLE_REQUESTS })
        : generatePath(ROUTES.buyer.dashboard.tab, { role: 'buyer', tab: DashboardMainTabs.QUOTE_REQUESTS });

      navigate({
        pathname,
        ...(quoteId && { search: `quoteId=${quoteId}` }),
      });
    }
  }, [isSampleType, isSuccessAddQuote, navigate, quoteId]);

  const isSuccessRequest = useMemo(() => {
    return quoteId ? isSuccessAddQuote : isSuccess;
  }, [quoteId, isSuccessAddQuote, isSuccess]);

  return {
    isLoading,
    formError,
    onChange,
    onSubmit,
    isSuccess,
    addQuoteLoading,
    isSuccessAddQuote,
    isSuccessRequest,
  };
};
