import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  container: {
    width: '100%',
    boxSize: 'border-box',
    height: '340px',
    display: 'flex',
    gap: '16px',
    paddingTop: '16px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      flexDirection: 'column-reverse',
      height: 'auto',
      gap: '16px',
    },
  },
  listHeader: {
    fontSize: '16px',
    fontWeight: '600',
    backgroundColor: '#f5f5f5',
    padding: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  },
  listContainer: {
    border: '1px solid #f5f5f5',
    borderRadius: '8px',
    marginBottom: '8px',
    overflow: 'hidden',
  },
  listItemScroll: {
    maxHeight: '250px',
    padding: '8px 0px',
  },
  listItemContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: customTheme.palette.secondary.S10,
    },
  },
  checkBox: {
    height: '16px',
    width: '32px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  listSide: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  summarySide: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  summaryHeader: {},
  summaryItems: {
    display: 'flex',
    gap: '4px',
    flexWrap: 'wrap',
  },
  pill: (isRestricted: boolean) => ({
    padding: '2px 4px',
    borderRadius: '12px',
    ...(isRestricted
      ? { border: `1px solid ${customTheme.custom.reject}`, color: customTheme.custom.reject }
      : { border: `1px solid ${customTheme.palette.primary.main}`, color: customTheme.palette.primary.main }),
  }),
  allowedAll: {
    color: customTheme.palette.primary.main,
  },
  restrictedAll: {
    color: customTheme.custom.reject,
  },
};
