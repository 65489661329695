import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { snakeCase, startCase } from 'lodash';

import { useModal } from 'hooks';
import { EditButton } from 'components';
import { Category, CompanyData } from 'app/api/company/types';
import { CategoryTags } from './constants';
import { styles } from './styles';
import { EditCompanyModal } from '../EditCompanyModal';

interface PageHeaderProps extends Pick<CompanyData, 'displayId' | 'logo' | 'name' | 'address'> {
  categories?: Category[];
  isSelf?: boolean;
}

export const PageHeader: FC<PageHeaderProps> = ({ displayId, logo, name, address, categories, isSelf }) => {
  const { open, handleCloseModal, handleOpenModal } = useModal();
  const onClickEdit = () => {
    handleOpenModal();
  };
  return (
    <>
      <Box sx={{ ...styles.wrap }}>
        {logo && <Box component="img" loading="lazy" src={logo} alt={name || ''} sx={{ ...styles.image }} />}
        <Box sx={{ ...styles.nameWrap }}>
          <Typography component="h2" sx={{ ...styles.name }}>
            {name}
          </Typography>
          <Typography sx={{ ...styles.location }}>
            {address.city ? `${address.city}, ` : ''} {address.country}
          </Typography>
        </Box>
        {isSelf && (
          <Box sx={styles.editContainer}>
            <EditButton onClick={onClickEdit} />
          </Box>
        )}
        {isSelf && (
          <EditCompanyModal
            displayId={displayId}
            city={address.city}
            title="Company"
            handleClose={handleCloseModal}
            isOpen={open}
          />
        )}
      </Box>
      {categories && (
        <Box sx={{ ...styles.tagsWrap }}>
          {categories.map((category) => (
            <Typography key={category} component="span" sx={{ ...styles.tag }}>
              {CategoryTags[category] ?? startCase(snakeCase(category))}
            </Typography>
          ))}
        </Box>
      )}
    </>
  );
};
