import { FC, MouseEvent, ReactNode } from 'react';
import { Box, Dialog, IconButton, SxProps, Typography } from '@mui/material';

import { ReactComponent as CloseIcon } from 'assets/icons/closeSmall.svg';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { useDeviceType } from 'hooks';
import { styles } from './styles';

interface CommonModalProps {
  isOpen: boolean;
  children: ReactNode;
  withCloseButton?: boolean;
  title?: string;
  paperStyles?: SxPropsTypes;
  titleStyles?: SxProps;
  titleContainerStyles?: SxProps;
  scroll?: 'body' | 'paper';
  handleClose: (event: MouseEvent, reason?: string) => void;
  disableEscapeKeyDown?: boolean;
}

export const CommonModal: FC<CommonModalProps> = ({
  title,
  children,
  withCloseButton = true,
  isOpen = false,
  paperStyles = {},
  titleStyles,
  titleContainerStyles,
  scroll,
  handleClose,
  disableEscapeKeyDown,
}) => {
  const { isMobileSize } = useDeviceType();
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      scroll={scroll}
      disableEscapeKeyDown={disableEscapeKeyDown}
      PaperProps={{
        style: { ...(isMobileSize ? styles.mobilePaper : styles.paper), ...paperStyles },
      }}
    >
      <Box sx={{ ...styles.titleWrap(!!title || withCloseButton), ...titleContainerStyles }}>
        <Box>{!!title && <Typography sx={{ ...styles.title, ...titleStyles }}>{title}</Typography>}</Box>

        {withCloseButton && (
          <IconButton sx={{ ...styles.closeButton }} onClick={(e) => handleClose(e)} data-testid="close-modal-button">
            <CloseIcon />
          </IconButton>
        )}
      </Box>

      {children}
    </Dialog>
  );
};
