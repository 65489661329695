import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  container: {
    border: '1px solid #58BE94',
    background: '#E6FFF5',
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    maxWidth: breakpoints.desktop,
    padding: '16px',
    display: 'flex',
    gap: '32px',
  },
  title: {
    color: '#58BE94',
    fontSize: '14px',
    fontWeight: 500,
    textWrap: 'wrap',
  },
  btn: {
    minWidth: '148px',
    color: customTheme.custom.white,
  },
};
