import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  mainWrap: {
    position: 'relative',
  },
  wrap: {
    marginBottom: '24px',
    display: 'flex',
    gap: '24px',
    width: '100%',
    overflow: 'hidden',
    height: '420px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      // height: 'fit-content',
      height: 'inherit',
      flexDirection: 'column',
      gap: 1,
    },
  },
  leftWrap: (isVertical: boolean, isPlaceholder: boolean, isLegend: boolean) => ({
    flex: '480px 0 1',
    position: 'relative',
    // maxWidth: isPlaceholder ? '400px' : '65%',
    overflow: 'hidden',
    borderRadius: '25px',

    ...(isLegend && {
      borderBottomRightRadius: 0,
    }),

    '@media (min-width: 1680px)': {
      ...((isVertical || isPlaceholder) && {
        borderBottomRightRadius: '25px',
      }),
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
      maxWidth: '100%',
      // height: 'fit-content',
      height: 'inherit',
      flex: 'inherit',
    },
  }),
  inner: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',

    // '@media (min-width: 1680px)': {
    background: 'rgba(69, 69, 69, 0.1)',
    backdropFilter: 'blur(12px)',
    // },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      // height: 'fit-content',
      height: 'inherit',
    },
  },
  bgImage: {
    // display: 'none',
    position: 'absolute',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',

    '@media (min-width: 1680px)': {
      display: 'block',
    },
  },
  outOfStock: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    background: customTheme.custom.gray.G100,
    opacity: 0.5,
  },
  outOfStockTag: {
    display: 'inline-flex',
    padding: '4px 8px',
    justifyContent: 'flex-start',
    alignItems: 'center',

    position: 'absolute',
    fontWeight: 600,
    color: customTheme.custom.black,
    right: '0px',
    top: '27px',
    width: '100px',
    fontSize: '14px',

    borderRadius: '8px 0px 0px 8px',
    background: customTheme.custom.gray.G100,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.2)',
  },
  image: (isVertical: boolean) => ({
    width: '100%',
    height: '420px',
    objectFit: 'contain',
    objectPosition: 'center',

    '@media (min-width: 1680px)': {
      ...(isVertical && {
        objectFit: 'contain',
      }),
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',
      width: '100%',
      maxWidth: '100%',
      objectFit: 'contain',
      height: 'auto',
    },
  }),
  cardWrap: {
    flex: '370px 1 1',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      flex: 'inherit',
    },
  },
  minOrder: {
    position: 'absolute',
    right: '12px',
    bottom: '-16px',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '10px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      bottom: '8px',
    },
  },
  productActionContainer: {
    display: 'inline-flex',
    alignItems: 'flex-start',
    gap: '8px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      justifyContent: 'flex-end',
      // width: '100%',
      marginBottom: 1,
    },
  },
};
