import { patchCompanyData } from 'app/api/company';
import { PatchCompanyData } from 'app/api/company/types';
import { useMutation, useQueryClient } from 'react-query';
import { notificationObserver } from 'utils/observer';

export const useUpdateCompanyDetails = (displayId: string, onUpdate?: () => void) => {
  const queryClient = useQueryClient();

  const { mutate: handleUpdateCompany } = useMutation(async (data: PatchCompanyData) => {
    try {
      await patchCompanyData(displayId, data);
      notificationObserver.publish({
        type: 'success',
        title: 'Updated company details',
      });
      await queryClient.invalidateQueries({ queryKey: ['company', displayId] });
      if (onUpdate) onUpdate();
    } catch (err) {
      notificationObserver.publish({
        type: 'warning',
        title: 'Updated update company details',
      });
    }
  }, {});

  return { handleUpdateCompany };
};
