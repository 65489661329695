import { lazy } from 'react';
import { useAppSelector } from 'state/hooks';
import { selectOnboardingModal } from 'state/slices/account';

const Onboarding = lazy(() => import('components/PopupOnboarding/Onboarding'));

export const PopupOnboarding = () => {
  const { isOpen } = useAppSelector(selectOnboardingModal);
  if (!isOpen) return null;
  return <Onboarding />;
};
