import { FC, MouseEvent, useState } from 'react';

import { Box, ButtonBase, Menu, MenuItem, SxProps, Typography } from '@mui/material';
import { CommonTooltip } from 'components';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

import { UnitsType } from 'app/api/quotes';

import { styles } from './styles';

const unitTypeMap = {
  [UnitsType.KG]: 'kg',
  [UnitsType.LB]: 'lb',
  [UnitsType.THOUSAND_SEEDS]: 'thousand seeds',
};
export const getUnitTypeLabel = (unitType: UnitsType) => {
  return unitTypeMap[unitType];
};

const options = [
  { label: 'kg', value: UnitsType.KG },
  { label: 'lb', value: UnitsType.LB },
  { label: 'thousand seeds', value: UnitsType.THOUSAND_SEEDS },
];

export const UnitsMenu: FC<{
  units?: UnitsType;
  onChange: (unit: UnitsType) => void;
  sxContainer?: SxProps;
  sxLabel?: SxProps;
  isEditable?: boolean;
}> = ({ units, onChange, sxContainer = {}, sxLabel = {}, isEditable = true }): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [open, setIsOpen] = useState<boolean>(false);
  const setOpen = () => setIsOpen(true);
  const handleClick = () => {
    setIsOpen(true);
  };
  const handleClose = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(false);
  };

  const onSelect = (value: UnitsType, e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(value);
    setIsOpen(false);
  };
  const displayUnit = options.find((option) => option.value === units);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const labelStyles: any = sxLabel;
  return (
    <Box sx={{ ...styles.units, ...sxContainer }} onClick={setOpen}>
      <Typography sx={{ ...styles.unitsLabel, ...labelStyles }}>{displayUnit?.label ?? ''}</Typography>
      {isEditable && (
        <>
          <CommonTooltip title="Change Units" placement="top" sx={styles.unitsTooltip}>
            <ButtonBase
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              ref={setAnchorEl}
              sx={styles.unitsButton}
            >
              <EditIcon />
            </ButtonBase>
          </CommonTooltip>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              dense: true,
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.label} onClick={(e: MouseEvent) => onSelect(option.value, e)}>
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </Box>
  );
};
