import { FC } from 'react';

import { Avatar, Box, Typography } from '@mui/material';

import { EditButton } from 'components';
import { useModal } from 'hooks';
import { TeamMember } from 'app/api/company/types';
import avatarPlaceholder from 'assets/images/teamMemberPlaceholder.png';
import { getInitials } from 'utils/userName';
import { getFileExtension } from '../../utils';
import { styles } from './styles';
import { EditUserModal } from '../EditUserModal';

type AvatarCardProps = TeamMember & { isSelf?: boolean };

export const AvatarCard: FC<AvatarCardProps> = ({ id, name, role, photo, isSelf = false }) => {
  const avatarExtension = getFileExtension(photo);
  const src = avatarExtension === 'pdf' || !photo ? avatarPlaceholder : photo;
  const { open, handleCloseModal, handleOpenModal } = useModal();
  const onClickEdit = () => {
    handleOpenModal();
  };

  return (
    <Box sx={{ ...styles.wrap }}>
      <Avatar alt={name} src={src} sx={{ ...styles.avatar }}>
        {getInitials(name)}
      </Avatar>
      <Box>
        <Typography sx={{ ...styles.role }}>
          {role}
          {isSelf && (
            <Box sx={styles.editContainer}>
              <EditButton onClick={onClickEdit} />
            </Box>
          )}
        </Typography>
        <Typography sx={{ ...styles.name }}>{name}</Typography>
      </Box>
      {isSelf && (
        <EditUserModal id={id} img={src} name={name} role={role} handleClose={handleCloseModal} isOpen={open} />
      )}
    </Box>
  );
};
