import * as rudderanalytics from 'rudder-sdk-js';
import posthog from 'posthog-js';

export const page = (metadata: object = {}) => {
  try {
    rudderanalytics.page();
    posthog.capture('$pageview', {
      $current_url: window.location.href,
      ...metadata,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('tracking.page error:', err);
  }
};

export const track = (eventName: string, properties?: object) => {
  try {
    // eslint-disable-next-line no-console
    rudderanalytics.track(eventName, properties as rudderanalytics.apiObject);
    posthog.capture(eventName, properties);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('tracking.track error:', err);
  }
};

export const identify = (userId: string, traits?: object) => {
  try {
    // eslint-disable-next-line no-console
    rudderanalytics.identify(userId, traits as rudderanalytics.apiObject);
    posthog.identify(userId, traits);
    // Only record session recording for logged in users
    posthog.startSessionRecording();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('tracking.identify error:', err);
  }
};

export const reset = () => {
  try {
    // eslint-disable-next-line no-console
    rudderanalytics.reset();
    posthog.reset();
    posthog.stopSessionRecording();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('tracking.reset error:', err);
  }
};

export type apiObject = rudderanalytics.apiObject;
