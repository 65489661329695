import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  modalPaper: {},
  saveButton: {
    minWidth: '80px',
    marginBottom: '16px',
  },
  input: {
    borderRadius: '8px',
    border: '1px solid var(--Black-10, #E8E8E8)',
    background: customTheme.custom.white,
    padding: '4px 6px',
    color: customTheme.custom.black.B50,
  },
  inputLabel: {
    color: customTheme.custom.gray.G400,
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: '8px',
  },
  inTouch: {
    textAlign: 'center',
    minWidth: '520px',
    fontSize: '14px',
    fontWeight: 500,
    textWrap: 'wrap',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minWidth: 'inherit',
    },
  },
  contact: {
    color: customTheme.palette.primary.main,
    textDecoration: 'underline',
  },
};
