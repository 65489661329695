import { FC } from 'react';

import { Box } from '@mui/material';

import { useAuth } from 'hooks';
import { useForm } from 'react-hook-form';
import { CommonButton, CommonModal, TextFormField } from 'components';
import { useUpdateCompanyDetails } from 'pages/CompanyDetailsPage/hooks/updateCompanyDetails';

import { styles } from './styles';

interface EditCompanyModalProps {
  title: string;
  handleClose: () => void;
  isOpen: boolean;
  city: string;
  displayId: string;
}

const messages = {
  name: 'Company Name',
  city: 'City',
  inTouch: 'Please get in touch with us here to change your country - ',
  contact: 'Contact Us',
};

type CompanyFormData = {
  name: string;
  city: string;
};

export const EditCompanyModal: FC<EditCompanyModalProps> = ({
  displayId,
  city,
  title,
  handleClose,
  isOpen = false,
}) => {
  const { activeUser } = useAuth();
  const { handleUpdateCompany } = useUpdateCompanyDetails(displayId, handleClose);

  const onClickSave = (data: CompanyFormData) => {
    handleUpdateCompany(data);
  };
  const { control, handleSubmit } = useForm<CompanyFormData>({
    defaultValues: {
      name: activeUser?.company.name || '',
      city,
    },
  });
  return (
    <CommonModal title={title} isOpen={isOpen} handleClose={handleClose}>
      <Box>
        <Box component="label" sx={styles.inputLabel}>
          {messages.name}
        </Box>
        <TextFormField control={control} name="name" placeholder="Company Name" />
      </Box>
      <Box>
        <Box component="label" sx={styles.inputLabel}>
          {messages.city}
        </Box>
        <TextFormField control={control} name="city" placeholder="city" />
      </Box>
      <CommonButton fullWidth color="primary" sx={styles.saveButton} onClick={handleSubmit(onClickSave)}>
        Save
      </CommonButton>
      <Box sx={styles.inTouch}>
        {messages.inTouch}
        <Box component="span" sx={styles.contact}>
          {messages.contact}
        </Box>
      </Box>
    </CommonModal>
  );
};
