import { FC, useCallback } from 'react';

import { generatePath } from 'react-router-dom';
import { Box, Link, Typography } from '@mui/material';

import { ProductDetail } from 'app/api/products/types';
import { ROUTES } from 'app/routes/constants';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { AnalyticsLinkType, useLinkClickedEvent } from 'analytics';
import { formatNumber, formatQuantityAvailableUnits } from 'utils/formatText';
import { getUnitTypeLabel } from 'components/UnitsMenu';
import { styles } from './styles';

interface StatsTotalInfoProps {
  data: ProductDetail;
  isSeller?: boolean;
}

export const StatsTotalInfo: FC<StatsTotalInfoProps> = ({ data, isSeller }): JSX.Element => {
  const detailsPath = generatePath(ROUTES.common.product, { id: data.id });
  const isQuantityAvailable = !!data.qtyAvailable;
  const quantityAvailableUnits = data.qtyAvailableUnits || 'KG';
  const isMinimumOrder = !!data.company.smallestShipment;

  const { handleTrackLinkClickEvent } = useLinkClickedEvent();

  const handleFullDetailsClick = useCallback(() => {
    handleTrackLinkClickEvent({ path: detailsPath, text: 'See full details', type: AnalyticsLinkType.LINK });
  }, [detailsPath, handleTrackLinkClickEvent]);

  return (
    <Box sx={{ ...styles.wrap }}>
      <Box sx={{ ...styles.infoWrap(!isQuantityAvailable || !isMinimumOrder) }}>
        {data.company.smallestShipment && data.company.smallestShipmentType && (
          <Box sx={{ ...styles.itemWrap(isQuantityAvailable) }} data-testid="minimum-order">
            <Typography sx={{ ...styles.totalLabel }}>Minimum Order: </Typography>
            <Typography sx={{ ...styles.totalValue }}>
              {formatNumber(data.company.smallestShipment)} {getUnitTypeLabel(data.company.smallestShipmentType)}
            </Typography>
          </Box>
        )}
        {isQuantityAvailable && !isSeller && (
          <Box sx={{ ...styles.itemWrap(false) }} data-testid="quantity-available">
            <Typography sx={{ ...styles.totalLabel }}>Quantity Available: </Typography>
            <Typography sx={{ ...styles.totalValue, ...styles.quantityValue }}>
              {formatNumber(data.qtyAvailable ?? 0)}
              <Box component="span" sx={styles.units}>
                {formatQuantityAvailableUnits(quantityAvailableUnits)}
              </Box>
            </Typography>
          </Box>
        )}
      </Box>

      <Box sx={{ ...styles.buttonWrap }}>
        <Link href={detailsPath} target="_blank" sx={{ ...styles.detailsButton }} onClick={handleFullDetailsClick}>
          {isSeller ? 'View as Buyer' : 'See full details'}
          <Box sx={{ ...styles.menuButtonIcon }}>
            <ArrowIcon />
          </Box>
        </Link>
      </Box>
    </Box>
  );
};
