import { UnitsType } from '../quotes';

export interface Address {
  id: number;
  continent: string;
  country: string;
  city: string;
  address?: string | null;
}

export interface TeamMember {
  id: number;
  name: string;
  role: string;
  photo?: string;
}

export type PatchTeamMemberData = {
  name?: string;
  role?: string;
};

export type PatchCompanyData = {
  name?: string;
  description?: string;
  smallestShipment?: number;
  smallestShipmentType?: UnitsType;
  countriesRestrictedAt?: number[];

  city?: string;
};

export enum Category {
  breeder = 'BREEDER',
  producer = 'PRODUCER',
  exporter = 'EXPORTER',
  importer = 'IMPORTER',
  packetAndGarden = 'PACKET_AND_GARDEN',
  foodProduction = 'FOOD_PRODUCTION',
  distributor = 'DISTRIBUTOR',
  multiplier = 'MULTIPLIER',
  wholesaler = 'WHOLESALER',
  processor = 'PROCESSOR',
  farmerCoop = 'FARMER_COOP',
  seedBuyer = 'SEED_BUYER',
  other = 'OTHER',
}

export enum PreferredProduct {
  vegetable = 'VEGETABLE',
  fruit = 'FRUIT',
  grain = 'GRAIN',
  herb = 'HERB',
  forage = 'FORAGE',
  sproutAndMicrogreen = 'SPROUT_AND_MICROGREEN',
  pasture = 'PASTURE',
  turf = 'TURF',
  flowering = 'FLOWERING',
  organic = 'ORGANIC',
}

export enum ShipmentMethod {
  exw = 'EXW',
  fca = 'FCA',
  fob = 'FOB',
  fas = 'FAS',
  cfr = 'CFR',
  cif = 'CIF',
  cpt = 'CPT',
  cip = 'CIP',
  ddp = 'DDP',
  ddu = 'DDU',
  dap = 'DAP',
  dpu = 'DPU',
  other = 'Other',
}

export const ShipmentMethodLabels: Partial<{
  [key in ShipmentMethod]: string;
}> = {
  [ShipmentMethod.exw]: 'EXW (Ex Works)',
  // [ShipmentMethod.fca]: 'EXW (Ex Works)',
  [ShipmentMethod.fob]: 'FOB (Free On Board)',
  // [ShipmentMethod.fas]: 'EXW (Ex Works)',
  // [ShipmentMethod.cfr]: 'EXW (Ex Works)',
  [ShipmentMethod.cif]: 'CIF (Cost, Insurance, and Freight)',
  [ShipmentMethod.cpt]: 'CPT (Carriage Paid To)',
  // [ShipmentMethod.cip]: 'EXW (Ex Works)',
  [ShipmentMethod.ddu]: 'DDU (Delivered Duty Unpaid)',
  [ShipmentMethod.ddp]: 'DDP (Delivered Duty Paid)',
  [ShipmentMethod.other]: 'Other',
  // [ShipmentMethod.dap]: 'EXW (Ex Works)',
  // [ShipmentMethod.dpu]: 'EXW (Ex Works)',
};

interface Meta extends Record<string, unknown> {
  categories?: Category[];
  shipmentMethods?: ShipmentMethod[];
  preferredProducts?: PreferredProduct[];
}

export interface CompanyDetail {
  id: number;
  displayId: string;
  address: Address;
  hasSeller: boolean;
  yearEstablished?: number;
  image?: string | null;
  teamMembers: TeamMember[];
  createdAt?: string;
  name: string;
  website?: string | null;
  phoneNumber?: string | null;
  smallestShipment?: number | null;
  smallestShipmentType?: UnitsType;
  logo?: string | null;
  description?: string;
  meta?: Meta;
}

export type CompanyData = CompanyDetail;

export interface Company {
  id: number;
  displayId: string;
  name: string;
  logo?: string | null;
  image?: string | null;
  createdAt?: string;
  website?: string | null;
  phoneNumber?: string | null;
  smallestShipment?: number | null;
  smallestShipmentType?: UnitsType;
  description?: string;
}
