import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { scrollMixin } from 'theme/MuiThemeProvider/mixins';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  crop: {
    // color: #000;
    fontSize: '32px',
    fontWeight: '600',
    lineHeight: '1.1',
    marginBottom: '8px',
  },
  image: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  inner: (isBlur: boolean) => ({
    height: '100%',
    padding: '40px 24px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    borderTopRightRadius: '25px',
    borderBottomRightRadius: '25px',
    ...(isBlur && {
      background: 'rgba(69, 69, 69, 0.7)',
      backdropFilter: 'blur(25px)',
    }),
  }),
  top: {
    flex: 1,
    display: 'flex-column',
  },
  topContent: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  productActionContainer: {
    display: 'inline-flex',
    alignItems: 'flex-start',
    gap: '8px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      justifyContent: 'flex-end',
      // width: '100%',
      marginBottom: 1,
    },
  },
  iconButton: (isActive: boolean) => ({
    border: `1px solid ${customTheme.custom.black.B50}`,
    '& svg': {
      height: '24px',
      width: '24px',
    },
    ...(isActive && {
      backgroundColor: customTheme.palette.primary.P50,
    }),
  }),

  list: (isLimitedHeight: boolean) => ({
    display: 'grid',
    gap: '12px',
    maxHeight: isLimitedHeight ? '200px' : '100%',
    overflowY: 'auto',
    ...scrollMixin,
  }),
  keyStatsWrap: (isLimitedHeight: boolean) => ({
    maxHeight: isLimitedHeight ? '288px' : '100%',
    paddingBottom: '16px',
    overflowY: 'hidden',
  }),
  financingSection: {
    padding: '16px 0px 8px',
  },
  financingTooltip: {
    padding: '8px',
    fontSize: '12px',
    backgroundColor: 'rgba(39, 139, 159, 0.9)',
  },
  moneyPlant: {
    maxHeight: '16px',
    maxWidth: '16px',
    marginRight: '4px',
  },
  requestQuote: {
    fontSize: '14px',
    fontWeight: 700,
  },
  requestQuotePay: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 400,
    color: customTheme.custom.gray.G600,
  },
  requestQuoteDescripton: {
    '& svg': {
      height: '16px',
      width: '16px',
      marginLeft: '2px',
      verticalAlign: 'middle',
      '& path': {
        fill: customTheme.custom.black.B100,
      },
    },
  },
  requestQuoteFinance: {
    color: customTheme.custom.brightGreen,
    cursor: 'pointer',
  },
  // divider
  divider: {},

  // Request Quote Container
  requestQuoteContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '16px',
    backgroundColor: customTheme.custom.blue.B20,
    padding: '16px',
  },
  requestQuoteButton: {
    color: customTheme.custom.white,
    fontSize: '16px',
    fontWeight: 700,
  },
  quantity: {
    width: '100%',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    marginBottom: '16px',
  },
  quantityAvailable: {
    flex: 1,
    ':hover': {
      backgroundColor: 'inherit',
    },
  },
  quantityAvailableInput: {
    flex: 1,
    background: customTheme.custom.white,
    borderRadius: '16px',
    padding: '8px 16px',
    textAlign: 'left',
    '::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  unitsMenu: {
    backgroundColor: customTheme.custom.white,
    borderRadius: '16px',
    padding: '8px 8px 8px 16px',
  },

  // out of stock
  outOfStock: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: customTheme.custom.outOfStock,
    color: customTheme.custom.white,
    borderRadius: '4px',
    padding: '8px 32px',
    fontSize: '16px',
    fontWeight: 600,
    justifyContent: 'center',
    boxSizing: 'border-box',
    width: '100%',
  },
  outOfStockContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '16px 0px',
  },
  similarProducts: {
    cursor: 'pointer',
    marginTop: '4px',
    textDecoration: 'underline',
    fontSize: '14px',
    fontWeight: 500,
    color: customTheme.custom.gray.G500,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      fontWeight: 500,
      maxWidth: '90px',
    },
  },
  //
  tooltip: {
    padding: '8px',
    fontSize: '12px',
    backgroundColor: 'rgba(39, 139, 159, 0.9)',
  },

  // financing modal
  modalPaper: {
    maxWidth: '600px',
    // maxWidth: customTheme.breakpoints.values.tablet,
    display: 'flex',
  },
  closeBtn: {},
  financingModalBody: {
    marginBottom: '16px',
  },
  financingModalBottom: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    fontSize: '16px',
    fontWeight: 500,
  },
  financingModalApply: {
    color: customTheme.custom.white,
    width: '100px',
  },
  financingModalHelp: {
    fontSize: '12px',
    fontWeight: 400,
  },
};
