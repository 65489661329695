import { ReactElement } from 'react';

import { Box } from '@mui/material';
import semillas from 'assets/images/logo_tus_semillas.svg';
import basf from 'assets/images/basf_img.png';
import may from 'assets/images/may_img.png';
import chaiTai from 'assets/images/chai_tai.png';
import hollarSeeds from 'assets/images/hollarSeeds.png';
import indoAmerica from 'assets/images/indoamerica.png';
import knownYouSeed from 'assets/images/known-you-seed.png';
import ramiroArnedo from 'assets/images/ramiro-arnedo.png';
import seedBound from 'assets/images/seedbound.png';
import feltrinSeed from 'assets/images/feltrin-seed.png';
import condor from 'assets/images/condor-seed.png';
import agriseed from 'assets/images/agriseed.png';

import agrosel from 'assets/images/agrosel.png';
import asiaSeed from 'assets/images/asia_seed.png';
import barenburg from 'assets/images/barenburg.png';
import eastWest from 'assets/images/east-west-seed.png';
import frisian from 'assets/images/frisian.png';
import grainesVoltz from 'assets/images/graines_voltz.png';
import nuziveedu from 'assets/images/nuziveedu.png';
import terranova from 'assets/images/terranova.png';
import syngenta from 'assets/images/syngenta.png';

import { styles } from './styles';

// NOTE: We are unable to display Tus Semillas logo as they are not yet onboarded
const leadingSuppliers = [
  { name: 'BASF', logo: basf, withRadius: true },
  { name: 'Syngenta', logo: syngenta },
  { name: 'east west', logo: eastWest },
  { name: 'agriseed', logo: agriseed },
  { name: 'Tus Semillas', logo: semillas },
  { name: 'barenburg', logo: barenburg },
  { name: 'May', logo: may, withRadius: true },
  { name: 'Chai Tai', logo: chaiTai },
  { name: 'Hollar Seeds', logo: hollarSeeds },
  { name: 'graines voltz', logo: grainesVoltz },
  { name: 'knownYouSeed', logo: knownYouSeed },
  { name: 'indoAmerica', logo: indoAmerica },
  { name: 'condor', logo: condor },
  { name: 'feltrinSeed', logo: feltrinSeed },
  { name: 'seedBound', logo: seedBound },
  { name: 'ramiroArnedo', logo: ramiroArnedo },
  { name: 'terranova', logo: terranova },
  { name: 'asia seed', logo: asiaSeed },
  { name: 'frisian', logo: frisian },
  { name: 'agrosel', logo: agrosel },
  { name: 'nuziveedu', logo: nuziveedu },
];

export const LeadingSuppliers = (): ReactElement => {
  return (
    <Box sx={styles.container}>
      {leadingSuppliers.map((supplier) => (
        <Box
          key={supplier.name}
          component="img"
          src={supplier.logo}
          alt={supplier.name}
          sx={styles.logo(!!supplier.withRadius)}
        />
      ))}
    </Box>
  );
};
